import { useCallback, useMemo, useState } from 'react'
import {
    stringToAccessTokenSchema,
    base64DecodeURL,
    base64EncodeURL,
    createSchemaTransform,
    stringToJSONSchema,
} from '../util'
import zod from 'zod'
import { useQueryClient, useSuspenseQuery } from '@tanstack/react-query'
import { authKeySymbol, baseAppTokenSchema } from './shared'
import { HttpClient } from '@base-app/library'
import { appConfig } from '../app-config'

function createStorageKey() {
    return `authn.user:token`
}

const credentialStorageDataSchema = zod.object({
    accessToken: zod.string(),
    personId: zod.string().optional(),
})

function prepareCredentialStorageData(accessToken: string): zod.output<typeof credentialStorageDataSchema> {
    const tokenInfo = stringToAccessTokenSchema.transform(createSchemaTransform(baseAppTokenSchema)).parse(accessToken)
    return {
        personId: tokenInfo.person_id,
        accessToken: accessToken,
    }
}

const registrationParameters = {
    userVerification: 'preferred',
    attachment: 'unset',
    discoverableCredential: 'unset',
    attestation: 'none',
    residentKey: 'unset',
    pubKeyCredParams: [-65535, -259, -258, -257, -39, -38, -37, -36, -35, -8, -7],
}

type RegistrationOptionsResponse = {
    rp: { name: string; id: string }
    user: { id: string; name: string; displayName: string }
    challenge: string
    pubKeyCredParams: Array<{ type: 'public-key'; alg: number }>
}

type RequestOptions = {
    signal: AbortSignal
}

class WebAuthNClient {
    #httpClient: HttpClient
    constructor(httpClient: HttpClient) {
        this.#httpClient = httpClient
    }

    async createRegistrationOptions(requestOptions: RequestOptions) {
        const response = await this.#httpClient.post<RegistrationOptionsResponse>(
            requestOptions,
            'CreateRegistrationOptions',
            {
                registrationParameters,
            },
        )

        if (!response) {
            throw new Error('No response...')
        }

        const user = {
            id: base64DecodeURL(response.user.id),
            name: response.user.displayName,
            displayName: response.user.displayName,
        }

        return {
            signal: requestOptions.signal,
            publicKey: {
                challenge: base64DecodeURL(response.challenge),
                pubKeyCredParams: response.pubKeyCredParams,
                rp: response.rp,
                user: user,
                excludeCredentials: [],
                attestation: 'none',
                authenticatorSelection: {
                    authenticatorAttachment: 'platform',
                    requireResidentKey: true,
                },
                extensions: {
                    credProps: true,
                },
            },
        } satisfies CredentialCreationOptions
    }

    async createAuthenticationOptions(requestOptions: RequestOptions) {
        const options = {
            signal: requestOptions.signal,
            anonymous: true,
        }
        const response = await this.#httpClient.post<{
            timeout: number
            challenge: string
            rpId: string
        }>(options, 'CreateAuthenticationOptions', {})

        if (!response) {
            throw new Error('Oops')
        }

        return {
            challenge: base64DecodeURL(response.challenge),
            timeout: response.timeout,
            rpId: response.rpId,
        }
    }

    async completeRegistration(requestOptions: RequestOptions, credential: PublicKeyCredential): Promise<unknown> {
        if (!(credential.response instanceof AuthenticatorAttestationResponse)) {
            return
        }
        const publicKey = credential.response.getPublicKey()
        if (!publicKey) {
            throw new Error('Public key missing')
        }

        try {
            await this.#httpClient.post<unknown>(requestOptions, 'CompleteRegistration', {
                id: credential.id,
                rawId: base64EncodeURL(credential.rawId),
                response: {
                    clientDataJson: base64EncodeURL(credential.response.clientDataJSON),
                    authenticatorData: base64EncodeURL(credential.response.getAuthenticatorData()),
                    transports: credential.response.getTransports(),
                    publicKey: base64EncodeURL(publicKey),
                    publicKeyAlgorithm: credential.response.getPublicKeyAlgorithm(),
                    attestationObject: base64EncodeURL(credential.response.attestationObject),
                },
                authenticatorAttachment: credential.authenticatorAttachment,
                clientExtensionResults: credential.getClientExtensionResults(),
                type: credential.type,
            })
        } catch (reason) {
            // eslint-disable-next-line no-console
            console.error(reason)
            throw reason
        }

        return true
    }

    async completeAuthentication(
        requestOptions: RequestOptions,
        credential: PublicKeyCredential,
    ): Promise<string | false> {
        const options = {
            signal: requestOptions.signal,
            anonymous: true,
        }

        if (!(credential.response instanceof AuthenticatorAssertionResponse)) {
            throw new Error('No AuthenticatorAssertionResponse ')
        }

        if (!credential.response.userHandle) {
            throw new Error('Missing user handle')
        }

        try {
            const accessToken = await this.#httpClient.post<string>(options, 'CompleteAuthentication', {
                id: credential.id,
                rawId: base64EncodeURL(credential.rawId),
                response: {
                    clientDataJson: base64EncodeURL(credential.response.clientDataJSON),
                    authenticatorData: base64EncodeURL(credential.response.authenticatorData),
                    signature: base64EncodeURL(credential.response.signature),
                    userHandle: base64EncodeURL(credential.response.userHandle),
                },
                authenticatorAttachment: credential.authenticatorAttachment,
                clientExtensionResults: credential.getClientExtensionResults(),
                type: credential.type,
            })
            return zod.string().parse(accessToken)
        } catch (reason) {
            // eslint-disable-next-line no-console
            console.error(reason)
            return false
        }
    }
}

export function useCredentialLogin(getBaseAccessToken: () => Promise<{ accessToken: string } | undefined>) {
    // const [credential, setCredential] = useState<PublicKeyCredential | null>(null)
    const [isLoading, setIsLoading] = useState(false)
    const queryClient = useQueryClient()

    const invalidateQueries = useCallback(async () => {
        await queryClient.invalidateQueries({ queryKey: [authKeySymbol], exact: false })
    }, [queryClient])

    const { data: currentUser } = useSuspenseQuery({
        queryKey: [authKeySymbol, 'accessToken'],
        queryFn: () => {
            const storedCredential = sessionStorage.getItem(createStorageKey())
            if (storedCredential) {
                return stringToJSONSchema
                    .transform(createSchemaTransform(credentialStorageDataSchema))
                    .parse(storedCredential)
            }

            return null
        },
        refetchInterval: 10_000,
    })

    const client = useMemo(() => {
        const getToken = async () => {
            const token = await getBaseAccessToken()
            if (!token?.accessToken) {
                throw new Error('No token - no luck')
            }
            return token.accessToken
        }
        const httpClient = new HttpClient(appConfig.API_BASE_WEBAUTHN, getToken, {
            credentials: 'include',
        })
        return new WebAuthNClient(httpClient)
    }, [getBaseAccessToken])

    const create = useCallback(
        async (user: { name: string; id: string; displayName: string }) => {
            const abortController = new AbortController()

            const registrationOptions = await client.createRegistrationOptions(abortController)

            const credential = await navigator.credentials.create(registrationOptions)
            if (!(credential instanceof PublicKeyCredential)) {
                throw new Error('not a public key credential')
            }
            await client.completeRegistration(abortController, credential)
            localStorage.setItem(`storedCredential.${credential.id}`, JSON.stringify(user))
            await invalidateQueries()
        },
        [client, invalidateQueries],
    )

    const login = useCallback(
        async (credentialId: string) => {
            setIsLoading(true)
            try {
                const abortController = new AbortController()
                const authOptions = await client.createAuthenticationOptions(abortController)

                const credential = await navigator.credentials.get({
                    mediation: 'optional',
                    publicKey: {
                        challenge: authOptions.challenge,
                        rpId: authOptions.rpId,
                        allowCredentials: [
                            {
                                type: 'public-key',
                                id: base64DecodeURL(credentialId),
                            },
                        ],
                    },
                })

                if (!(credential instanceof PublicKeyCredential)) {
                    throw new Error('No credential')
                }

                const accessToken = await client.completeAuthentication(abortController, credential)
                if (accessToken) {
                    const storageId = createStorageKey()
                    const storageData = prepareCredentialStorageData(accessToken)
                    sessionStorage.setItem(storageId, JSON.stringify(storageData))
                    await invalidateQueries()
                }
            } finally {
                setIsLoading(false)
            }
        },
        [client, invalidateQueries],
    )

    const logout = useCallback(async () => {
        sessionStorage.removeItem(createStorageKey())
        await invalidateQueries()
    }, [invalidateQueries])

    return {
        login,
        logout,
        isLoading,
        create,
        accessToken: currentUser?.accessToken,
    }
}
