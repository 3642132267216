import { useSuspenseQuery } from '@tanstack/react-query'
import { useCurrentUser } from '@base-app/library'
import { useInstallations, useNotificationStatus } from '../notifications'

export function Home() {
    const user = useCurrentUser()
    const ip = useSuspenseQuery({
        queryFn: async ({ signal }) => {
            return (await user?.tokenClient.get<string>({ signal }, 'ip')) ?? 'no-ip'
        },
        queryKey: ['ip'],
    })
    const notificationStatus = useNotificationStatus()
    const installations = useInstallations()

    return (
        <div>
            <p>
                Hello {user?.profile?.firstName} (authenticated by {user?.profile?.authenticationSource})
            </p>
            <p>No place like {ip.data}</p>
            <h2>Notifications</h2>
            <p>{notificationStatus.status}</p>
            <h2>Identity</h2>
            {notificationStatus.status === 'denied' && (
                <p>
                    You have disallowed notifications in your browser. To use push notifications, this site needs to be
                    able to notify you of important updates.
                </p>
            )}
            <button
                type="button"
                onClick={() => notificationStatus.subscribe()}
                disabled={
                    notificationStatus.status === 'subscribed' ||
                    notificationStatus.status === 'denied' ||
                    notificationStatus.isBusy
                }
            >
                Subscribe
            </button>
            <button
                type="button"
                onClick={() => notificationStatus.unsubscribe()}
                disabled={notificationStatus.status !== 'subscribed' || notificationStatus.isBusy}
            >
                Unsubscribe
            </button>
            <h3>Installations across browsers</h3>
            <ul>
                {installations.data.map((item) => (
                    <li key={item.registrationId}>
                        <strong>{item.kind}</strong>
                        <ul>
                            {item.tags.map((tag) => (
                                <li key={tag}>{tag}</li>
                            ))}
                        </ul>
                    </li>
                ))}
            </ul>
            <hr />
            <button
                type="button"
                onClick={user.onLogout}
            >
                Log me off
            </button>
        </div>
    )
}
