import zod from 'zod'

export const authKeySymbol = Symbol.for('auth')
export const profileSchema = zod.object({
    cprUuid: zod.string(),
    age: zod.string(),
    firstName: zod.string(),
    lastName: zod.string(),
    fullName: zod.string(),
    cprNumber: zod.string(),
    authenticationSource: zod.string(),
})

export type Profile = zod.output<typeof profileSchema>

export const baseAppTokenSchema = zod.object({
    iss: zod.string(),
    aud: zod.string(),
    exp: zod.coerce.number(),
    jti: zod.string(),
    'https://data.gov.dk/model/core/eid/cprNumber': zod.string(),
    'https://data.gov.dk/model/core/eid/cprUuid': zod.string().optional(),
    'https://data.gov.dk/model/core/eid/age': zod.string(),
    'https://data.gov.dk/model/core/eid/firstName': zod.string(),
    'https://data.gov.dk/model/core/eid/lastName': zod.string(),
    'https://data.gov.dk/model/core/eid/fullName': zod.string(),
    'authentication-source': zod.string(),
    person_id: zod.string(),
    school_code: zod.string().optional(),
    municipality: zod.string().optional(),
})
