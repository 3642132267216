import { AppBar, Content, useCurrentUser } from '@base-app/library'
import { NavLink, Outlet, useLocation } from 'react-router-dom'
import { Suspense, useEffect, useRef, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useKnownCredentials } from './auth/use-known-credentials'

export function AppShell({ additionalMenuItems }: { additionalMenuItems?: Array<{ to: string; label: string }> }) {
    const currentUser = useCurrentUser()
    const errorBoundaryRef = useRef<ErrorBoundary>(null)
    const location = useLocation()
    const knownCredentials = useKnownCredentials()
    const [errors, setErrors] = useState<Record<string, string>>({})

    useEffect(() => {
        // Reset the error boundary on navigation change
        errorBoundaryRef.current?.resetErrorBoundary()
    }, [location.pathname])

    if (!currentUser.isAuthenticated) {
        return (
            <>
                <AppBar />
                <Content>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 15 }}>
                        <h2>Login Options</h2>
                        <button
                            onClick={currentUser.onRequestLogin}
                            type="button"
                            disabled={currentUser.isLoading}
                        >
                            Default Auth Provider (KMD Identity / MitId)
                        </button>
                        {knownCredentials.map((credential) => (
                            <div key={credential.credentialId}>
                                <button
                                    type="button"
                                    disabled={currentUser.isLoading || credential.credentialId in errors}
                                    onClick={async () => {
                                        try {
                                            await currentUser.credentialLogin?.login(credential.credentialId)
                                        } catch (reason) {
                                            if (reason instanceof Error) {
                                                setErrors((errors) => ({
                                                    ...errors,
                                                    [credential.credentialId]: `${reason.name}: ${reason.message}`,
                                                }))
                                            } else {
                                                throw reason
                                            }
                                        }
                                    }}
                                >
                                    WebAuthn: {credential.user.displayName}
                                </button>
                                {errors[credential.credentialId] ?? null}
                            </div>
                        ))}
                    </div>
                </Content>
            </>
        )
    }

    return (
        <>
            <AppBar />
            <Content>
                <nav style={{ display: 'flex', flexWrap: 'nowrap', gap: 4 }}>
                    <NavLink to="/">Home</NavLink>
                    <NavLink to="/remember">Remember</NavLink>
                    <NavLink to="/calendar">Calendar</NavLink>
                    {additionalMenuItems?.map((item) => (
                        <NavLink
                            key={item.to}
                            to={item.to}
                        >
                            {item.label}
                        </NavLink>
                    ))}
                </nav>
                <Suspense fallback={<p>Content loading</p>}>
                    <ErrorBoundary
                        fallbackRender={(props: { error: { message: string } }) => <p>Error: {props.error.message}</p>}
                        ref={errorBoundaryRef}
                    >
                        <Outlet />
                    </ErrorBoundary>
                </Suspense>
            </Content>
        </>
    )
}
